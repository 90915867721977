import Link from 'next/link';
import { useContext, useEffect } from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import Image from 'next/image';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { AuthContext } from '@/store/auth-context';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import Button from '@mui/material/Button';
import useIsMobile from '@/hooks/useIsMobile';
export async function getStaticProps(context) {
  const id = 'home';

  return {
    props: {
      id,
    },
  };
}
export default function Home(props) {
  const { setTriggerLogin, setFormToShow, formToShow, isLoggedIn } =
    useContext(AuthContext);
  const isMobile = useIsMobile('sm');
  console.log('isMobile', isMobile);
  useEffect(() => {
    if (
      props.userAction &&
      [
        'login',
        'register',
        'forgotPasswordReset',
        'verifyEmail',
        'verifyEmailChange',
        'verifyInvite',
        'qrCode',
      ].includes(props.userAction)
    ) {
      console.log('hello, in trigger login');
      setTriggerLogin(true);
      setFormToShow(props.userAction);
    }
  }, []);
  return (
    <>
      <Container>
        <Grid
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          alignContent={'center'}
          container
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={isLoggedIn ? 6 : 6}
            justifyContent="center"
            alignContent={'center'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src="/images/image-monitoring.svg"
                width={250}
                height={250}
                alt="Picture of a dashboard"
              />
              <Typography variant="h4" fontWeight={'800'} color="primary.dark">
                Online monitoring
              </Typography>
              <Typography variant="body" color="primary.dark">
                {`With Watermeln Cloud you have direct real-time insights in your Watermeln units showing fuel levels and performance. With Watermeln Cloud we perform proactive maintenance and ensure reliability and optimum uptime.`}
              </Typography>
            </Box>
          </Grid>
          {isLoggedIn && (
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignContent={'center'}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DashboardRoundedIcon
                  sx={{ width: 250, height: 250 }}
                  color="primary"
                />
                <Typography
                  variant="h4"
                  fontWeight={'800'}
                  color="primary.dark"
                >
                  View your data
                </Typography>
                <Typography variant="body" color="primary.dark">
                  Check the available units
                </Typography>
                <Link href="/units">
                  <Button variant="contained" color="secondary">
                    here
                  </Button>
                </Link>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      <div style={{ height: '100px' }} />
    </>
  );
}
